import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import RichText from "../components/rich-text"
import styled from "styled-components"
import { mq } from "../styles/variables"

const Text = styled.div`
  max-width: 672px;
  margin-bottom: 120px;
`

const Small = styled.div`
  font-size: 16px;

  h3 {
    font-size: 24px;
  }
`

const Heading = styled.h2`
  font-size: 32px;
  line-height: 1.1;
  margin: 0 0 0.5em;
`

const Logos = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 25px;
  margin: 50px 0;

  img {
    width: 100%;
  }

  @media ${mq.gte("m")} {
    grid-gap: 50px;
  }
`

const About = ({
  location,
  data: {
    sanityAbout: {
      title,
      _rawRichtext,
      supportedByHeading,
      supportedByLogos,
      _rawSupportedByRichtext,
      disclosuresHeading,
      _rawDisclosuresRichtext,
    },
  },
}) => {
  return (
    <Layout color="#F8EDE9" title={title} location={location}>
      <h1 className="reveal">{title}</h1>
      <Text className="reveal">
        {_rawRichtext && <RichText blocks={_rawRichtext} />}
      </Text>

      <Text className="reveal">
        <Heading>{supportedByHeading}</Heading>

        {supportedByLogos && (
          <Logos
            style={{
              maxWidth: `${
                160 * supportedByLogos.length +
                (supportedByLogos.length - 1) * 50
              }px`,
            }}
          >
            {supportedByLogos.map(({ asset: { url } }) => (
              <img src={url} alt="" key={url} />
            ))}
          </Logos>
        )}

        <Small>
          {_rawSupportedByRichtext && (
            <RichText blocks={_rawSupportedByRichtext} />
          )}
        </Small>
      </Text>

      <Text className="reveal">
        {disclosuresHeading && <Heading>{disclosuresHeading}</Heading>}

        <Small>
          {_rawDisclosuresRichtext && (
            <RichText blocks={_rawDisclosuresRichtext} />
          )}
        </Small>
      </Text>
    </Layout>
  )
}

export default About

export const query = graphql`
  query AboutPageQuery {
    sanityAbout {
      title
      _rawRichtext
      supportedByHeading
      supportedByLogos {
        asset {
          url
        }
      }
      _rawSupportedByRichtext
      disclosuresHeading
      _rawDisclosuresRichtext
    }
  }
`
